import { RenderNode } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

import { Box } from '../Box';
import RichText from '../RichText';
import {
  EmbeddedInlineEntryNode,
  HyperlinkNode,
  ParagraphNode
} from '../RichText/Nodes';
import { ImageAsset } from './ImageAsset';
import { VideoAsset } from './VideoAsset';

import { IAssetWith5050TextFields } from '../../../types/contentful';
import ImageLink from '../ImageLink';
import { UnorderedList } from './styles';
import { Eyebrow } from '../Eyebrow';
import cx from 'classnames';

interface Props extends IAssetWith5050TextFields {
  sysId?: string;
}

export function AssetWith5050Text({
  colorBackgroundOption,
  backgroundColor,
  headingText,
  bodyCopy,
  superheading,
  imageLink,
  asset,
  assetTitle,
  assetFirst,
  vimeoId,
  roundedCorners,
  checkmarkBullets,
  sysId
}: Props) {
  const RICH_TEXT_OVERRIDE: RenderNode = {
    [BLOCKS.UL_LIST]: (_node, children) =>
      checkmarkBullets ? (
        <ul className="styled-unordered-list">{children}</ul>
      ) : (
        <UnorderedList className="!pl-9">{children}</UnorderedList>
      ),
    [INLINES.HYPERLINK]: (node, children) => (
      <HyperlinkNode node={node} colour="inlineAnchor">
        {children}
      </HyperlinkNode>
    ),
    [INLINES.EMBEDDED_ENTRY]: (node, children) => (
      <EmbeddedInlineEntryNode
        node={node}
        colour="inlineAnchor"
        fontWeight="inlineAnchor"
      >
        {children}
      </EmbeddedInlineEntryNode>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <ParagraphNode node={node} className="!text-base">
        {children}
      </ParagraphNode>
    )
  };

  return (
    <>
      <div
        className="block-container"
        data-elementtype="assetWith5050Text"
        data-elementid={sysId}
        id={sysId}
        data-testid="assetWith5050Text"
      >
        <div
          className={cx('layout flex-col', {
            'tablet:flex-row': assetFirst,
            'tablet:flex-row-reverse': !assetFirst,
            '[&>div]:tablet:p-3 [&>div]:desktop:p-4': colorBackgroundOption,
            '!py-0 [&>div]:tablet:px-3 [&>div]:desktop:px-4':
              !colorBackgroundOption
          })}
          style={{
            backgroundColor:
              colorBackgroundOption && backgroundColor
                ? backgroundColor
                : 'transparent'
          }}
        >
          <div className={`column w-full tablet:w-6/12`}>
            {vimeoId && asset ? (
              <VideoAsset
                asset={asset}
                title={assetTitle}
                vimeoId={vimeoId}
                rounded={roundedCorners ?? false}
              />
            ) : imageLink ? (
              <ImageLink
                {...{
                  ...imageLink.fields,
                  linkProps: {
                    className: 'w-full relative tablet:w-auto tablet:flex-1'
                  }
                }}
              >
                {(image) => (
                  <ImageAsset
                    asset={image}
                    title={assetTitle}
                    rounded={roundedCorners ?? false}
                  />
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                )}
              </ImageLink>
            ) : asset ? (
              <ImageAsset
                asset={asset}
                title={assetTitle}
                rounded={roundedCorners ?? false}
              />
            ) : null}
          </div>
          <div
            className={`column mt-6 flex w-full justify-center tablet:mt-0 tablet:w-6/12`}
          >
            <div
              id="text-wrapper"
              className="border-box flex w-full flex-col justify-center tablet:w-auto tablet:max-w-[480px] tablet:flex-1"
            >
              {superheading && <Eyebrow eyebrowFields={superheading.fields} />}
              <div
                className="heading-2 pb-5"
                data-blockid="assetWith5050Text-headline"
                data-testid="assetWith5050Text-headline"
              >
                {headingText}
              </div>
              {bodyCopy && (
                <Box data-blockid="assetWith5050Text-subhead">
                  <RichText
                    data={bodyCopy}
                    overrideOptions={RICH_TEXT_OVERRIDE}
                  />
                </Box>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
