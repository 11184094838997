import React from 'react';
import { IEyebrowFields } from '@/types/contentful';
import { UserData } from '../../lib/referrals/getUserDataById';
import { ProfileAvatarWithText } from './ProfileAvatarWithText';
import { EyebrowHomepage } from './EyebrowHomepage';
import { toSentenceCase } from '~/utils/stringUtils';

interface EyebrowProps {
  eyebrowFields: IEyebrowFields;
  advocateData?: UserData | null;
}
export const Eyebrow = ({ eyebrowFields, advocateData }: EyebrowProps) => {
  if (eyebrowFields.type === 'InvitedBy') {
    return (
      <ProfileAvatarWithText
        imageUrl={advocateData?.avatar?.signedRequestList[0]}
        name={advocateData?.name || ''}
        text={eyebrowFields.text || ''}
      />
    );
  } else if (eyebrowFields.type === 'text-only') {
    return eyebrowFields.text ? (
      <div className="mb-4">
        <p
          data-blockid="assetWith5050Text-superhead"
          data-testid="assetWith5050Text-superhead"
        >
          {toSentenceCase(eyebrowFields.text)}
        </p>
      </div>
    ) : null;
  } else {
    return <EyebrowHomepage {...eyebrowFields} />;
  }
};
