import styled from '../../theme/styled-with-theme';
import { pxToRem } from '../../styles/utils';

export const UnorderedList = styled('ul')`
  margin-left: -${pxToRem(8)};

  li {
    list-style-type: disc;
    margin: 0 0 0 -${pxToRem(8)};
    padding: 0 0 ${pxToRem(8)} 0;
  }

  li > ul:first-of-type {
    padding: 0 0 0 ${pxToRem(40)};
    margin-bottom: 0;
  }

  li > ul:first-of-type > li {
    background: none;
    list-style-type: disc;
    margin-bottom: 0;
  }
`;
